import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-info">
        <h2 className='fh1'>Contact Us</h2>
        <p>SSNR Online Shop</p>
        <p>Dhaka, Bangladesh</p>
        <p>Email: ssnronline@gmail.com</p>
        <p>Phone: (+88) 01712-871285</p>
      </div>
      
      <div className="footer-social">
        <h2 className='fh1'>Follow Us</h2>
        <div className="social-icons">
          <div className='fh2'>
            <a href="https://www.facebook.com/ssnronlineshop" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} /></a>
            <a href="https://www.instagram.com/ssnronline.shop/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></a>
            <a href="https://www.instagram.com/ssnronline.shop/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
            {/* Add more social media icons as needed */}
          </div>
        </div>
      </div>
      
      <div>
        <h2 className='fh1'>Quick Links</h2>
        <p className="text-center mt-3">
          <Link to="/about">About</Link><br></br>
          <Link to="/contact">Contact</Link><br></br>
          <Link to="/policy">Privacy Policy</Link>
        </p>
      </div>      

      {/* Copyright line */}
      <div className="copyright">
        <p className="fff">&copy; SSNR Online Shop. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
