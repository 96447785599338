export const Prices = [
  {
    _id: 0,
    name: "৳1 to 100",
    array: [1, 100],
  },
  {
    _id: 1,
    name: "৳1 to 500",
    array: [1, 500],
  },
  {
    _id: 2,
    name: "৳1 to 1000",
    array: [1, 1000],
  },
  {
    _id: 3,
    name: "৳100 to 500",
    array: [100, 500],
  },
  {
    _id: 4,
    name: "৳500 to 1000",
    array: [80, 99],
  },
  {
    _id: 4,
    name: "৳1000 or more",
    array: [1000, 9999],
  },
];
